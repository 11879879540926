import Amplify, { Auth } from 'aws-amplify'

// Helpers
import { getRandomString } from '../utils/helpers'

let cognitoUser // Track authentication flow state in this object

Amplify.configure({
    Auth: {
        region: 'us-east-1',
        userPoolId: process.env.REACT_APP_AWS_COGNITO_POOLID,
        userPoolWebClientId: process.env.REACT_APP_AWS_COGNITO_CLIENTID,
        oauth: {
            domain: process.env.REACT_APP_COGNITO_DOMAIN,
            scope: [ 'openid', 'email', 'profile', 'phone' ],
            responseType: 'code',
        },
    },
})

export async function signUp({ email, name, phone_number }) {
    const params = {
        username: email,
        password: getRandomString({ bytes: 30 }),
        attributes: {
            given_name: name,
            phone_number,
        },
    }
    const res = await Auth.signUp(params)
    return res
}

export async function signIn({ email }) {
    cognitoUser = await Auth.signIn(email)
}

export async function answerCustomChallenge(answer) {
    cognitoUser = await Auth.sendCustomChallengeAnswer(cognitoUser, answer)

    try {
        await Auth.currentSession()
    } catch (e) {
        console.error('Apparently the user did not enter the right code')
    }
}

export const signOut = () => {
    return Auth.signOut()
}
