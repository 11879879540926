import React, { useState, useEffect } from 'react'
import { useMediaQuery } from 'react-responsive'
import { useSearchParams } from 'react-router-dom'

// Components
import { Title } from '../../components/Title'
import { InputsFilter } from './components/InputsFilter'
import { Filter, FilterMobile } from './components/Filter'
import { FeaturedPropertiesList } from '../../components/FeaturedPropertiesList'
import { Button } from '../../components/Button'
import { Pagination } from '../../components/Pagination'
import { LoadingCardProperty, LoadingFilter } from '../../components/Loading'
import { OrderBy } from '../../components/OrderBy'

// Hook
import { useOpenAndCloseHook, useProperties, useFeatured } from '../../hooks'

// Icons
import { ArrowLeftIcon, FilterIcon } from '../../components/Svg'

// Fields
import {
    FIELD_KEY_LOCATION,
    FIELD_KEY_TYPE,
    FIELD_KEY_PRICE,
    FIELD_KEY_PROPERTY_CODE,
    FIELD_ORDER_BY,
} from '../../utils/fields'

// Const
import { MIN_PRICE } from '../../const/inputRange'

// Helpers
import { scrollTop } from '../../helpers/utils'

const TYPE_ONLY_VALID = 'only'

const Properties = () => {
    const [ params ] = useSearchParams()

    const {
        thereIsFilterUrl,
        listProperties,
        dataFilterProperties,
        loadingProperties,
        totalPage,
        page,
        getProperties,
        filterProperties,
        handleChangePageProperty,
        defaultFilter,
        createUriFilter,
    } = useProperties()

    const { listFeatures, getFeatures } = useFeatured()
    const [ loadingPage, setLoadingPage ] = useState(true)
    const [ modalFilterTablet, setModalFilterTablet ] = useOpenAndCloseHook()
    const isLaptopOrMobile = useMediaQuery({
        query: '(max-width: 1300px)',
    })

    const validUriParams = ({ valid = 'all' } = {}) => {
        if (valid === TYPE_ONLY_VALID)
            return (
                params.get(FIELD_KEY_LOCATION) ||
                params.get(FIELD_KEY_TYPE) ||
                params.get(FIELD_KEY_PRICE) ||
                params.get(FIELD_KEY_PROPERTY_CODE)
            )

        return (
            !params.get(FIELD_KEY_LOCATION) &&
            !params.get(FIELD_KEY_TYPE) &&
            !params.get(FIELD_KEY_PRICE) &&
            !params.get(FIELD_KEY_PROPERTY_CODE)
        )
    }

    const createUriFilterDefault = () => {
        if (validUriParams()) return ''

        const uriFilter = {}
        if (params.get(FIELD_KEY_LOCATION))
            uriFilter[FIELD_KEY_LOCATION] = [
                {
                    label: params.get(FIELD_KEY_LOCATION),
                    value: params.get(FIELD_KEY_LOCATION),
                },
            ]

        if (params.get(FIELD_KEY_TYPE))
            uriFilter[FIELD_KEY_TYPE] = [
                {
                    label: params.get(FIELD_KEY_TYPE),
                    value: params.get(FIELD_KEY_TYPE),
                },
            ]
        if (params.get(FIELD_KEY_PRICE))
            uriFilter[FIELD_KEY_PRICE] = {
                min: MIN_PRICE,
                max:
            Number(params.get(FIELD_KEY_PRICE)) >= MIN_PRICE
                ? Number(params.get(FIELD_KEY_PRICE))
                : MIN_PRICE,
            }
        if (params.get(FIELD_KEY_PROPERTY_CODE))
            uriFilter[FIELD_KEY_PROPERTY_CODE] = params.get(FIELD_KEY_PROPERTY_CODE)

        return defaultFilter({
            data: uriFilter,
        })
    }

    useEffect(() => {
        (async () => {
            try {
                scrollTop()
                const filter = thereIsFilterUrl ? createUriFilter({ datafilter: dataFilterProperties, page }) : await createUriFilterDefault()
                
                await getProperties({ filter })
                await getFeatures()
            } catch (error) {
                console.error(error)
            } finally {
                setLoadingPage(false)
            }
        })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleFilterProperties = ({ field, value }) => {
        filterProperties({ field, value })
    }

    return (
        <div className='container'>
            <div className='content propertiesPage'>
                <div className='propertiesPage_filter'>
                    <div className='propertiesPage_filter_title'>
                        <ArrowLeftIcon />
                        <Title size='lg'>Propiedades</Title>
                    </div>

                    {!loadingPage ? (
                        <>
                            <InputsFilter
                                data={dataFilterProperties}
                                onChangeFilter={handleFilterProperties}
                            />

                            <Filter
                                data={dataFilterProperties}
                                onChangeFilter={handleFilterProperties}
                                filterFeatureList={listFeatures.data}
                            />
                        </>
                    ) : (
                        <LoadingFilter />
                    )}
                </div>
                <div className='propertiesPage_content'>
                    <div className='propertiesPage_content_header'>
                        <div className='propertiesPage_filter_title propertiesPage_title_mobile'>
                            <ArrowLeftIcon />
                            <Title size='md'>Propiedades</Title>
                        </div>

                        <div className='propertiesPage_content_header_btn'>
                            {isLaptopOrMobile && (
                                <Button
                                    color='secundary'
                                    rounded
                                    transparent
                                    textColor='primary'
                                    icon
                                    className='propertiesPage_content_filter'
                                    onClick={setModalFilterTablet}
                                >
                                    <FilterIcon />
                                </Button>
                            )}

                            <OrderBy
                                mobile={isLaptopOrMobile}
                                orderBy={dataFilterProperties[FIELD_ORDER_BY]}
                                onChangeFilter={handleFilterProperties}
                            />
                        </div>
                    </div>
                    <div className='propertiesPage_content_list'>
                        {loadingPage || loadingProperties ? (
                            <LoadingCardProperty count={4} />
                        ) : (
                            <>
                                {listProperties.data.length > 0 && (
                                    <>
                                        <FeaturedPropertiesList data={listProperties.data} />
                                        <Pagination
                                            className='propertiesPage_pagination'
                                            page={Number(page)}
                                            countPage={Number(totalPage)}
                                            onChangePage={handleChangePageProperty}
                                        />
                                    </>
                                )}
                            </>
                        )}
                    </div>
                </div>
            </div>

            {isLaptopOrMobile && (
                <>
                    {modalFilterTablet && (
                        <FilterMobile
                            data={dataFilterProperties}
                            onChangeFilter={handleFilterProperties}
                            close={setModalFilterTablet}
                            filterFeatureList={listFeatures.data}
                        />
                    )}
                </>
            )}
        </div>
    )
}

export default Properties
