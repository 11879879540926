import { useState, useCallback } from 'react'
import { API_PUBLIC } from '../service/api'

// Hooks
import { useDebounce, usePagination } from './'

// Utils
import {
    FIELDS_PROPERTY,
    FIELD_KEY_PRICE,
    FIELD_KEY_FEATURES,
    FIELD_KEY_LOCATION,
    FIELD_KEY_PROPERTY_CODE,
    FIELDS_FEATUES,
    FIELD_KEY_TYPE,
    FIELD_KEY_BATHROOMS,
    FIELD_KEY_ROOMS,
    FIELD_KEY_PARKINGLOT,
    FIELD_KEY_ADMINISTRATION,
    FIELD_KEY_TOTAL_AREA,
    FIELD_KEY_BUILT_AREA,
    FIELD_ORDER_BY,
} from '../utils/fields'
import { decodeFilterFromBase64, encodeFilterToBase64, updateUrlWithFilter } from '../utils/helpers'

// Const
import { MIN_PRICE } from '../const/inputRange'
import { ORDER_BY_PUBLISHED_AT } from '../const/queryEndpoint'
import { LIST_ORDER_BY } from '../const/selectValues'

const POPULATE = 'populate'
const ENDPOINT = '/propiedades'
const ITEM_FOR_PAGE = 10

const useProperties = () => {
    const urlParams = new URLSearchParams(window.location.search)
    const base64Filters = urlParams.get('filters')
    
    const [ listProperties, setListProperties ] = useState({})
    const [ propertyInfo, setPropertyInfo ] = useState({})
    const [ errorProperties, setErrorProperties ] = useState('')
    const [ loadingProperties, setLoadingProperties ] = useState(false)
    const [ dataFilterProperties, setDataFilterProperties ] = useState(
        base64Filters
            ? decodeFilterFromBase64({ base64String: base64Filters, defaultR: {
                [FIELD_ORDER_BY]: LIST_ORDER_BY[1],
            } })
            : [],
    )
    const { totalPage, page, handleChangePage, handleConfigPage } =
    usePagination({ defaultPage: urlParams.get('page') })

    // buscar las propiedades destacadas
    const getFeaturedProperties = async () => {
        try {
            setLoadingProperties(true)
            const res = await API_PUBLIC({
                endpoint: `${ENDPOINT}/?filters[${FIELDS_PROPERTY.featuredProperty}][$eq]=true&${POPULATE}[${FIELDS_PROPERTY.imgPrimary}][${POPULATE}]&${ORDER_BY_PUBLISHED_AT}`,
            })
            setListProperties(res)
        } catch (error) {
            setErrorProperties(error)
        } finally {
            setLoadingProperties(false)
        }
    }

    // buscar una propiedad por id
    const getProperty = async ({ id }) => {
        try {
            setLoadingProperties(true)
            const res = await API_PUBLIC({
                endpoint: `${ENDPOINT}/${id}?${POPULATE}[${FIELDS_PROPERTY.placesOfInterest}][${POPULATE}]=%2A&${POPULATE}[${FIELDS_PROPERTY.features}][${POPULATE}]=&${POPULATE}[${FIELDS_PROPERTY.galery}][${POPULATE}]&${POPULATE}[${FIELDS_PROPERTY.imgPrimary}][${POPULATE}]`,
            })
            setPropertyInfo(res.data)
        } catch (error) {
            setErrorProperties(error)
        } finally {
            setLoadingProperties(false)
        }
    }

    // Buscar todas las propiedades
    const getProperties = async ({ filter = '' } = {}) => {
        try {
            setLoadingProperties(true)
            const res = await API_PUBLIC({
                endpoint: `${ENDPOINT}?${POPULATE}=*&${
                    filter ||
                    `${createUriPagination({ page: 1 })}&${ORDER_BY_PUBLISHED_AT}`
                }`,
            })

            if (totalPage !== res.meta.pagination.total)
                handleConfigPage({
                    totalItem: res.meta.pagination.total,
                    itemForPage: ITEM_FOR_PAGE,
                })

            setListProperties(res)
            return res
        } catch (error) {
            setErrorProperties(error)
        } finally {
            setLoadingProperties(false)
        }
    }

    // Filtrar la lista
    const createUriPagination = ({ page }) => {
        return `pagination[pageSize]=${ITEM_FOR_PAGE}&pagination[page]=${page}`
    }

    // Filter between
    const createUriFilterBetween = ({ item, datafilter }) => {
        return Object.keys(datafilter[item])
            .map(
                (itemRange) =>
                    `filters[${FIELDS_PROPERTY[item]}][$between]=${
                        datafilter[item][itemRange] || 0
                    }`,
            )
            .join('&')
    }

    const createUriFilter = ({ datafilter, page }) => {
        return [
            ...Object.keys(datafilter).map((item) => {
                // Filters key $contains
                if (
                    [
                        FIELD_KEY_LOCATION,
                        FIELD_KEY_PROPERTY_CODE,
                        FIELD_KEY_TYPE,
                    ].includes(item)
                ) {
                    if (Array.isArray(datafilter[item])) {
                        return datafilter[item]
                            .map(
                                (filter) =>
                                    `filters[${FIELDS_PROPERTY[item]}][$eq]=${filter.value}`,
                            )
                            .join('&')
                    }

                    return `filters[${FIELDS_PROPERTY[item]}][$contains]=${datafilter[item]}`
                }

                // Filter key $and for Features
                if (item === FIELD_KEY_FEATURES && datafilter[item].length > 0) {
                    return datafilter[item]
                        .map(
                            (itemFeature, index) =>
                                `filters[$and][${index}][${FIELDS_PROPERTY[item]}][${FIELDS_FEATUES.name}][$contains]=${itemFeature}`,
                        )
                        .join('&')
                }

                // Filter key $eq
                if (
                    [ FIELD_KEY_BATHROOMS, FIELD_KEY_ROOMS, FIELD_KEY_PARKINGLOT ].includes(
                        item,
                    ) &&
                    datafilter[item]
                ) {
                    if (Array.isArray(datafilter[item])) {
                        return datafilter[item]
                            .map(
                                (filter, index) =>
                                    `filters[$or][${index}][${FIELDS_PROPERTY[item]}][$eq]=${filter.value}`,
                            )
                            .join('&')
                    }

                    return `filters[${FIELDS_PROPERTY[item]}][$eq]=${datafilter[item]}`
                }

                // Filter key $between
                if (
                    datafilter[item] &&
                    ((datafilter[item].max > 0 && datafilter[item].min > 0) ||
                    (FIELD_KEY_ADMINISTRATION === item &&
                    datafilter[item].max !== null))
                ) {
                    // PRICE
                    if (
                        FIELD_KEY_PRICE === item &&
                        datafilter[item].max - 1 > MIN_PRICE
                    ) {
                        return createUriFilterBetween({ datafilter, item })
                    }

                    if (
                        [
                            FIELD_KEY_ADMINISTRATION,
                            FIELD_KEY_PRICE,
                            FIELD_KEY_TOTAL_AREA,
                            FIELD_KEY_BUILT_AREA,
                        ].includes(item) &&
                        datafilter[item] &&
                        datafilter[item].max - 1 !== datafilter[item].min
                    ) {
                        return createUriFilterBetween({ datafilter, item })
                    }
                }

                // Order by
                if (
                    item === FIELD_ORDER_BY &&
                    datafilter[FIELD_ORDER_BY].value !== ''
                ) {
                    return `sort=${FIELDS_PROPERTY[FIELD_KEY_PRICE]}%3A${datafilter[FIELD_ORDER_BY].value}`
                }

                return ORDER_BY_PUBLISHED_AT
            }),
            createUriPagination({ page }),
        ]
            .filter(Boolean)
            .join('&')
    }

    const filterProperties = ({ field, value }) => {
        const filter = {
            ...dataFilterProperties,
            [field]: value,
        }

        const urlFilter = createUriFilter({ datafilter: filter, page: 1 })

        handleChangePage({ page: 1 })
        setDataFilterProperties(filter)

        filterDebounce({ urlFilter, filter, page: 1 })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const filterDebounce = useCallback(
        useDebounce(({ urlFilter, filter, page }) => {
            updateUrlWithFilter({
                listParams: [
                    ...(filter ? [ { key: 'filters', value: encodeFilterToBase64({ filter }) } ] : []),
                    { key: 'page', value: page },
                ],
            })

            getProperties({ filter: urlFilter })
        }, 1000),
        [],
    )

    const handleChangePageProperty = async ({ page }) => {
        handleChangePage && handleChangePage({ page })
        filterDebounce({
            urlFilter: createUriFilter({
                datafilter: dataFilterProperties,
                page,
            }),
            filter: dataFilterProperties,
            page,
        })
    }

    const defaultFilter = async ({ data }) => {
        setDataFilterProperties(data)
        return createUriFilter({ datafilter: data, page: 1 })
    }

    return {
        propertyInfo,
        listProperties,
        errorProperties,
        loadingProperties,
        dataFilterProperties,
        totalPage,
        page,
        thereIsFilterUrl: !!base64Filters,
        getFeaturedProperties,
        getProperty,
        getProperties,
        filterProperties,
        handleChangePageProperty,
        defaultFilter,
        createUriFilter,
    }
}

export default useProperties
