import { useState } from 'react'

import { scrollTop } from '../helpers/utils'

const usePagination = ({ defaultPage } = {}) => {
    const [ page, setPage ] = useState(Number(defaultPage || 1))
    const [ totalPage, setTotalPage ] = useState(0)

    const handleChangePage = ({ page }) => {
        scrollTop()
        setPage(page)
    }

    const handleConfigPage = ({ totalItem, itemForPage }) => {
        setTotalPage(Math.ceil(totalItem / itemForPage))
    }

    return {
        page,
        totalPage,
        handleChangePage,
        handleConfigPage,
    }
}

export default usePagination
