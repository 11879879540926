import React, { useState } from 'react'
// import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'

// Hooks
// import { useLogin } from '../../hooks'

// Cognito
import {
    signIn,
    answerCustomChallenge,
} from '../../service/AwsCognitoConector'

// Componenst
import { Modal } from '.'
import { Title } from '../Title'
import { Button, ButtonGoogle, ButtonFacebook } from '../Button'
import { Input } from '../Input'
import { ConfirmCode } from '../../pages/Auth/components/ConfirmCode'
// import { ConfirmEmail } from '../../pages/Auth/components/CofirmEmail'

// Fields
import { FIELD_KEY_EMAIL, FIELD_KEY_PHONE } from '../../utils/fields'

// Icons
import { ArrowRightIcon } from '../Svg'

// Helpers
import { validateEmail } from '../../utils/helpers'

const MODAL_PHONE = 1
const MODAL_EMAIL = 2

const Login = ({ close }) => {
    const [ step, setStep ] = useState(MODAL_PHONE)
    const [ email, setEmail ] = useState('')
    const [ emailError, setEmailError ] = useState('')

    const [ phone, setPhone ] = useState('')
    const [ modalCofirm, setModalConfirm ] = useState(false)
    // const { login } = useLogin()
    // const navigate = useNavigate()

    const handleChangeStep = () => {
        setStep(step === MODAL_PHONE ? MODAL_EMAIL : MODAL_PHONE)
    }

    const handleChangeDataLogin = ({ field, value }) => {
        if (field === FIELD_KEY_EMAIL) {
            if (emailError) setEmailError('')

            setEmail(value)
        }

        if (field === FIELD_KEY_PHONE) {
            setPhone(value)
        }
    }

    const handleChangeModalConfirm = async () => {
        if (step === MODAL_EMAIL) {
            if (!validateEmail({ email }))
                return setEmailError('El email no es correcto')

            await signIn({ email })
        }

        setModalConfirm(!modalCofirm)
    }

    const handleLogin = async ({ code }) => {
        await answerCustomChallenge(code)
    // login();
    // navigate('/');
    }

    return (
        <Modal header='Iniciar Sesión' onClose={close}>
            <div className='modalLogin'>
                {!modalCofirm && (
                    <>
                        <Title size='sm'>Entra a tu cuenta Elemental</Title>

                        <div className='modalLogin_btns'>
                            <ButtonFacebook textShort />
                            <ButtonGoogle textShort />
                        </div>

                        {step === MODAL_PHONE && (
                            <Input
                                label='Teléfono'
                                name={FIELD_KEY_PHONE}
                                placeholder='Número celular (+57)'
                                value={phone}
                                onChange={(e) =>
                                    handleChangeDataLogin({
                                        field: e.target.name,
                                        value: e.target.value,
                                    })
                                }
                            />
                        )}

                        {step === MODAL_EMAIL && (
                            <Input
                                label='Email'
                                name={FIELD_KEY_EMAIL}
                                value={email}
                                placeholder='Email'
                                error={emailError}
                                onChange={(e) =>
                                    handleChangeDataLogin({
                                        field: e.target.name,
                                        value: e.target.value,
                                    })
                                }
                            />
                        )}

                        <div className='modalLogin_btns'>
                            <Button alignSelfStart rounded onClick={handleChangeModalConfirm}>
                                {step === MODAL_PHONE ? 'Entrar' : 'Comenzar con Email'}
                            </Button>

                            <Button
                                textColor='gray'
                                transparent
                                icon
                                underline
                                onClick={handleChangeStep}
                                colorIcon='gray'
                                jContentStart
                            >
                                {step === MODAL_PHONE
                                    ? 'Iniciar sesión con Email'
                                    : 'Iniciar con celular'}
                                <ArrowRightIcon color='gray' />
                            </Button>
                        </div>
                    </>
                )}

                {modalCofirm && (
                    <>
                        <ConfirmCode handleConfirmCode={handleLogin} />
                        {/* {step === MODAL_PHONE ? (
            ) : (
              <ConfirmEmail email={email || ''} />
            )} */}
                    </>
                )}
            </div>
        </Modal>
    )
}

Login.propTypes = {
    close: PropTypes.func,
}

export default Login
